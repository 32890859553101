// eslint-disable-next-line no-unused-vars
/* global __webpack_public_path__:true  */

function loadBundlePolyFilled(initialState, breadcrumbs) {
  /**
   *  Code split point for loading main bundle.
   */
  Promise.all([
    import(/* webpackChunkName: "core" */ "./startup"),
    import(
      /* webpackChunkName: "ld" */ "./createLaunchDarklyProvider"
    ).then(({ default: createLaunchDarklyProvider }) =>
      createLaunchDarklyProvider(initialState),
    ),
  ]).then(([{ startupWithBundle }, LDProvider]) => {
    startupWithBundle(initialState, breadcrumbs, LDProvider);
  });
}

function loadBundle(initialState, publicPath, breadcrumbs) {
  if (publicPath) {
    /* eslint-disable camelcase */
    __webpack_public_path__ = publicPath;
    /* eslint-enable camelcase */
  }

  // FIXME: why do we need to disable this?
  // eslint-disable-next-line import/no-unresolved
  require("web-streams-polyfill");

  if (typeof Intl === "undefined") {
    /**
     *  Safari doesn't support Intl, polyfill it.
     */
    require.ensure(
      [],
      (require) => {
        const warn = require("../shared/utils/Utilities").warn;
        require("intl");
        require("../shared/polyfills/IntlCollator");
        warn("Some internationalization features are unavailable");
        require("intl/locale-data/jsonp/en.js");
        require("intl/locale-data/jsonp/de.js");
        require("intl/locale-data/jsonp/fr.js");
        require("intl/locale-data/jsonp/es.js");
        loadBundlePolyFilled(initialState, breadcrumbs);
      },
      "polyfills",
    );
  } else {
    /**
     *  Don't polyfill when Intl is available.
     */
    loadBundlePolyFilled(initialState, breadcrumbs);
  }
}

/**
 *  When running in browser, call back into main HTML page which will
 *  in turn call loadBundle with the initial Redux state.
 */
if (typeof document !== "undefined") {
  document.addEventListener("DOMContentLoaded", () => {
    if (window.preloaded) {
      window.preloaded(loadBundle);
    }
  });
}
